import { useAsync } from "react-async-hook";
import { $axios } from "../https/axiosInstance";
import { CreateCleanSearchParams } from "../helpers/helpers";

export const AnalyticsService = {
  GetAnalyticsClients(searchParams: any) {
    return useAsync(async () => {
      return await $axios.get(
        "/staff/client-list/" + CreateCleanSearchParams(searchParams)
      );
    }, [CreateCleanSearchParams(searchParams)]);
  },
  GetAnalyticsManagerPrecentageSum(searchParams: any) {
    return useAsync(async () => {
      return await $axios.get(
        "/staff/manager-precentage-sum/" + CreateCleanSearchParams(searchParams)
      );
    }, [CreateCleanSearchParams(searchParams)]);
  },
    GetAnalyticsManagerSalary(searchParams: any) {
    return useAsync(async () => {
      return await $axios.get(
        "/staff/manager-salary/" + CreateCleanSearchParams(searchParams)
      );
    }, [CreateCleanSearchParams(searchParams)]);
  },
  GetClientIncome(searchParams: any) {
    return useAsync(async () => {
      return await $axios.get(
        "/staff/income/" + CreateCleanSearchParams(searchParams)
      );
    }, [CreateCleanSearchParams(searchParams)]);
  },
  GetClientExpense(searchParams: any) {
    return useAsync(async () => {
      return await $axios.get(
        "/staff/expense/" + CreateCleanSearchParams(searchParams)
      );
    }, [CreateCleanSearchParams(searchParams)]);
  },
  GetClientNetprofit(searchParams: any) {
    return useAsync(async () => {
      return await $axios.get(
        "/staff/netprofit/" + CreateCleanSearchParams(searchParams)
      );
    }, [CreateCleanSearchParams(searchParams)]);
  },

  async GetManagerPercentageSum(id: any) {
    return await $axios.get(`/salary/manager-percentage-sum/${id}/`);
  },
};
