import React, { useEffect, useState } from 'react'
import Header from '../components/UserHeader'
import {
    Autocomplete,
    Box,
    Button,
    IconButton,
    Modal,
    Pagination,
    TextField,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import moment from 'moment'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { SalaryService } from '../service/SalaryService'
import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { checkModalResponse } from '../helpers/helpers'
import { StaffService } from '../service/StaffService'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { useSnackbar } from '../helpers/SnackbarProvider'
import { GraphicsService } from '../service/GraphicsService'
import randomColor from 'randomcolor'
import { ru } from 'date-fns/locale/ru'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title)

const tableInitialValues = {
    rows: [],
    status: {
        loading: false,
        error: false,
        message: '',
    },
    filter: {
        search: '',
        user: '',
        page: 1,
        size: 20,
        total_pages: 1,
        date: '',
    },
}

const modalInitialValues = {
    open: false,
    values: {
        id: '',
        salary: '',
        accountant_percentage: '',
        percentage_amount: '',
        tax: '',
        user: '',
        created_at: null,
    },
    validation: {
        error: {
            salary: false,
            accountant_percentage: false,
            percentage_amount: false,
            tax: false,
            user: false,
        },
        message: {
            salary: '',
            accountant_percentage: '',
            percentage_amount: '',
            tax: '',
            user: '',
        },
    },
    requested: false,
    action: '',
}

export const Salary = () => {
    const { openSnackbar } = useSnackbar()
    const userId = useSelector((state: any) => state.userData.user.id)
    const user = useSelector(
        (state: any) => state.userData?.user?.position?.name,
    )

    const [table, setTable] = useState<any>({
        ...tableInitialValues,
        columns: [
            {
                field: 'index',
                headerName: '№',
                flex: 1,
                renderCell: (params: any) =>
                    params.api.getAllRowIds().indexOf(params.id) + 1,
            },
            {
                field: 'user',
                headerName: 'Менеджер',
                flex: 2,
                renderCell: (params: any) => params.row.user?.full_name,
            },
            {
                field: 'created_at',
                headerName: 'Дата',
                flex: 1,
                renderCell: (params: any) =>
                    moment(params.row.created_at).format('MM-YYYY'),
            },
            {
                field: 'accountant_percentage',
                headerName: 'Сумма бухгалтера',
                flex: 1,
            },
            { field: 'tax', headerName: 'Сумма налога', flex: 1 },
            { field: 'salary', headerName: 'Оклад', flex: 1 },
            {
                field: 'percentage_amount',
                headerName: 'Сумма от процентов',
                flex: 1,
                renderCell: (params: any) =>
                    params.row.percentage_amount.toFixed(0),
            },
            {
                field: 'total_salary',
                headerName: 'Итог',
                flex: 1,
                renderCell: (params: any) => {
                    const value = params.value || 0
                    return value.toFixed(0)
                },
            },
            {
                field: 'confirm',
                headerName: 'Действия',
                width: 115,
                renderCell: (params: any) => (
                    <div>
                        <Button
                            onClick={() => {
                                handleConfirmation(
                                    params.row.id,
                                    //   params.value,
                                    params.row.confirm === 'confirm'
                                        ? 'confirmed'
                                        : 'confirm',
                                    params.row.user?.id,
                                    moment(params.row.created_at)
                                        .format('YYYY-MM')
                                        .toString(),
                                )
                            }}
                            variant="contained"
                            //   color={params.value ? "black" : "inherit"}
                            color={
                                params.row.confirm === 'confirmed'
                                    ? 'black'
                                    : 'inherit'
                            }
                            className="py-[10px] px-[12px]"
                            style={{
                                borderRadius: '100px',
                                fontSize: '10px',
                            }}
                        >
                            {params.row.confirm === 'confirmed'
                                ? 'Подтверждено'
                                : 'Подтвердить'}
                        </Button>
                    </div>
                ),
            },

            {
                field: 'actions',
                headerName: 'Действия',
                width: 110,
                renderCell: (params: any) => (
                    <div className="flex justify-start gap-2">
                        <IconButton
                            onClick={() => {
                                setModal({
                                    ...modal,
                                    open: true,
                                    action: 'edit',
                                    values: {
                                        ...params.row,
                                        user: params.row.user?.id,
                                        created_at: moment(
                                            params?.row?.created_at,
                                            'YYYY-MM',
                                        ).format('YYYY-MM'),
                                    },
                                })
                                setClient(params.row.user?.full_name)
                            }}
                        >
                            <EditOutlinedIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                setModal({
                                    ...modal,
                                    open: true,
                                    action: 'delete',
                                    values: {
                                        ...params.row,
                                        user: params.row.user?.id,
                                        created_at: dayjs(
                                            params.row.created_at,
                                        ),
                                        // end_date: dayjs(params.row.end_date),
                                    },
                                })
                                setClient(params.row.user?.full_name)
                            }}
                        >
                            <DeleteOutlineOutlinedIcon />
                        </IconButton>
                    </div>
                ),
            },
        ],
    })

    const [modal, setModal] = useState<any>(modalInitialValues)

    const handleFormSubmit = (event: React.FormEvent) => {
        event.preventDefault()
        setModal({
            ...modal,
            requested: true,
        })

        const values = {
            ...modal.values,
            // ...(modal.values.date !== null && {
            //     created_at: moment(modal.values.created_at.$d)
            //         .format('YYYY-MM')
            //         .toString(),
            // }),
        }

        switch (modal.action) {
            case 'add':
                SalaryService.CreateSalary(values)
                    .then(() => {
                        setModal(modalInitialValues)
                        setClient('')
                        tableList.execute()
                        openSnackbar('Оклад успешно добавлен', {
                            variant: 'success',
                        })
                    })
                    .catch((err) => {
                        checkModalResponse(err.response.data, setModal, modal)
                        openSnackbar('Не удалось добавить оклад', {
                            variant: 'error',
                        })
                    })

                break

            case 'edit':
                SalaryService.UpdateSalary(values)
                    .then(() => {
                        setModal(modalInitialValues)
                        setClient('')
                        tableList.execute()
                        openSnackbar('Оклад успешно изменён', {
                            variant: 'success',
                        })
                    })
                    .catch((err) => {
                        checkModalResponse(err.response.data, setModal, modal)
                        openSnackbar('Не удалось изменить оклад', {
                            variant: 'error',
                        })
                    })

                break

            case 'delete':
                SalaryService.DeleteSalary(values)
                    .then(() => {
                        setModal(modalInitialValues)
                        setClient('')
                        tableList.execute()
                        openSnackbar('Оклад успешно удалён', {
                            variant: 'success',
                        })
                    })
                    .catch((err) => {
                        checkModalResponse(err.response.data, setModal, modal)
                        openSnackbar('Не удалось удалить оклад', {
                            variant: 'error',
                        })
                    })
                break

            default:
                break
        }
    }

    const tableList = SalaryService.GetSalaryList(table.filter)

    const [client, setClient] = useState('')

    useEffect(() => {
        if (tableList.loading) {
            setTable((prevState: any) => ({
                ...prevState,
                status: {
                    ...prevState.status,
                    loading: true,
                },
            }))
        } else if (tableList.error) {
            setTable((prevState: any) => ({
                ...prevState,
                status: {
                    ...prevState.status,
                    loading: false,
                    error: true,
                },
            }))
        } else {
            const data = tableList.result?.data
            setTable((prevState: any) => ({
                ...prevState,
                rows: data.results,
                status: {
                    ...prevState.status,
                    loading: false,
                    error: false,
                },
                filter: {
                    ...prevState.filter,
                    page: data.current_page,
                    total_pages: data.total_pages,
                },
            }))
        }
    }, [tableList.loading, tableList.error, tableList.result?.data])

    const managers = StaffService.GetManagers()

    const handleConfirmation = (
        id: any,
        confirmation: any,
        user: any,
        created_at: any,
    ) => {
        const updatedRows: any = table.rows.map((row: any) =>
            row.id === id ? { ...row, confirm: confirmation, user: user } : row,
        )
        setTable({ ...table, rows: updatedRows })

        const values = {
            id: id,
            user: user,
            confirm: confirmation,
            created_at: created_at,
        }

        SalaryService.UpdateSalary(values)
            .then(() => {
                tableList.execute()
            })
            .catch((error) => {
                console.error('Ошибка обновления данных:', error)
            })
    }

    // const filtersInitialValues = {
    //   date: {
    //     date_from: dayjs().subtract(2, "month"),
    //     date_to: dayjs(),
    //   },
    // };

    const filtersInitialValues = {
        date: {
            date_from: dayjs().subtract(2, 'month').format('YYYY-MM'),
            date_to: dayjs().format('YYYY-MM'),
        },
    }

    const [filter, setFilter] = useState<any>(filtersInitialValues)

    // const analyticsManagerSalary = GraphicsService.GetGraphicsSalaryList({
    //   ...filter.date,
    //   ...(filter.date.date_from === null
    //     ? {}
    //     : {
    //         date_from: moment(filter.date.date_from?.$d).format("YYYY-MM"),
    //       }),
    //   ...(filter.date.date_to === null
    //     ? {}
    //     : {
    //         date_to: moment(filter.date.date_to?.$d).format("YYYY-MM"),
    //       }),
    // });

    const analyticsManagerSalary = GraphicsService.GetGraphicsSalaryList({
        ...filter.date,
        date_from: filter.date.date_from
            ? moment(filter.date.date_from).format('YYYY-MM')
            : null,
        date_to: filter.date.date_to
            ? moment(filter.date.date_to).format('YYYY-MM')
            : null,
    })

    const datasetColors = new Array(
        analyticsManagerSalary?.result?.data?.manager_data?.length || 0,
    )
        .fill(null)
        .map(() => randomColor())

    return (
        <>
            <Header pageTitle={'Зарплата'} />

            <div className="mt-[103px] flex justify-between items-center w-full">
                <div className="flex gap-6">
                    <FormControl className="w-[169px]" variant="standard">
                        <InputLabel>Менеджер</InputLabel>
                        <Select
                            label="Менеджер"
                            required={true}
                            value={table.filter.user}
                            onChange={(event: any) => {
                                setTable({
                                    ...table,
                                    filter: {
                                        ...table.filter,
                                        user: event.target.value,
                                    },
                                })
                            }}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {!managers.loading &&
                                !managers.error &&
                                managers.result?.data.map(
                                    (group: any, index: number) => (
                                        <MenuItem key={index} value={group.id}>
                                            {group.full_name}
                                        </MenuItem>
                                    ),
                                )}
                        </Select>
                    </FormControl>
                    <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={ru}
                    >
                        <DatePicker
                            className="w-[169px]"
                            label="Дата"
                            onChange={(newValue: any) => {
                                let formattedDate = ''
                                if (newValue) {
                                    formattedDate =
                                        moment(newValue).format('YYYY-MM')
                                }
                                setTable({
                                    ...table,
                                    filter: {
                                        ...table.filter,
                                        date: formattedDate,
                                    },
                                })
                            }}
                            slotProps={{
                                textField: { variant: 'standard' },
                                field: { clearable: true },
                            }}
                            views={['year', 'month']}
                            minDate={new Date('2000-01-01')}
                            maxDate={new Date('2050-12-31')}
                        />
                    </LocalizationProvider>
                </div>

                <Button
                    variant="contained"
                    color="black"
                    style={{
                        borderRadius: '100px',
                        padding: '16px 30px',
                        lineHeight: 'normal',
                    }}
                    onClick={() =>
                        setModal({ ...modal, open: true, action: 'add' })
                    }
                >
                    Добавить зарплату
                </Button>
            </div>

            <Box sx={{ width: '100%' }} className="mt-[40px]">
                <DataGrid
                    rows={table.rows}
                    columns={table.columns}
                    checkboxSelection={false}
                    keepNonExistentRowsSelected
                    disableColumnFilter
                    disableColumnMenu
                    hideFooter
                    autoHeight
                    loading={table.status.loading}
                    disableRowSelectionOnClick
                    sx={{
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: '#979CFF',
                            color: '#FFF',
                        },
                        '.MuiDataGrid-iconButtonContainer': {
                            display: 'none',
                        },
                    }}
                />
            </Box>
            <div className="w-full flex gap-[20px] items-center justify-end mt-[20px]">
                <div className="flex items-center gap-[10px]">
                    <p>Rows:</p>
                    <TextField
                        label=""
                        variant="outlined"
                        size="small"
                        type="number"
                        sx={{ width: '100px' }}
                        value={table.filter.size}
                        onChange={(event) => {
                            setTable({
                                ...table,
                                filter: {
                                    ...table.filter,
                                    size: event.target.value,
                                },
                            })
                        }}
                    />
                </div>
                <Pagination
                    count={table.filter.total_pages}
                    page={table.filter.page}
                    onChange={(event, value: number) => {
                        setTable({
                            ...table,
                            filter: {
                                ...table.filter,
                                page: value,
                            },
                        })
                    }}
                />
            </div>

            <div className="flex gap-[50px] px-[30px] py-[20px] shadow-base rounded-[10px] border-[#f2f2f2] border-[2px]">
                <div>
                    <h4 className="pb-[10px] border-[#111] border-b-[1px] font-bold text-[14px] text-[#282828]">
                        Общая зарплата
                    </h4>
                    <span className="inline-block mt-[10px] font-medium text-[14px[">
                        {!tableList.loading && !tableList.error
                            ? tableList?.result?.data[
                                  'total_salaries'
                              ].toLocaleString('ru-RU')
                            : '0'}
                    </span>
                </div>
            </div>

            <div className="mt-[80px] w-full">
                <h1 className="text-[32px] font-semibold">Сумма зарплаты</h1>

                <div className="flex items-center gap-[25px] my-[60px]">
                    <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={ru}
                    >
                        <DatePicker
                            className="w-[129px]"
                            value={filter.date.date_from}
                            onChange={(newValue) => {
                                setFilter({
                                    ...filter,
                                    date: {
                                        ...filter.date,
                                        date_from: newValue,
                                    },
                                })
                            }}
                            slotProps={{
                                textField: {
                                    variant: 'standard',
                                    required: true,
                                },
                            }}
                            views={['year', 'month']}
                            minDate={new Date('2000-01-01')}
                            maxDate={new Date('2050-12-31')}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={ru}
                    >
                        <DatePicker
                            className="w-[129px]"
                            value={filter.date.date_to}
                            onChange={(newValue) => {
                                setFilter({
                                    ...filter,
                                    date: {
                                        ...filter.date,
                                        date_to: newValue,
                                    },
                                })
                            }}
                            slotProps={{
                                textField: {
                                    variant: 'standard',
                                    required: true,
                                },
                            }}
                            views={['year', 'month']}
                            minDate={new Date('2000-01-01')}
                            maxDate={new Date('2050-12-31')}
                        />{' '}
                    </LocalizationProvider>

                    {/* {!employeesList.loading && !employeesList.error && (
            <FormControl className="w-[169px]" variant="standard">
              <InputLabel id="demo-multiple-checkbox-label">
                Сотрудник
              </InputLabel>
              <Select
                multiple
                value={modal.values.employees}
                onChange={(e) => {
                  setModal({
                    ...modal,
                    values: {
                      ...modal.values,
                      employees: e.target.value,
                    },
                  });
                }}
                renderValue={(selected) => {
                  const arr = [];
                  const data = employeesList.result?.data;
                  for (let i = 0; i < selected.length; i++) {
                    for (let j = 0; j < data.length; j++) {
                      if (data[j].id === selected[i]) {
                        arr.push(data[j].full_name);
                      }
                    }
                  }
                  return arr.join(", ");
                }}
                MenuProps={MenuProps}
              >
                {employeesList.result?.data.map(
                  (employee: any, index: number) => (
                    <MenuItem key={index} value={employee.id}>
                      <Checkbox
                        checked={modal.values.employees.includes(employee.id)}
                      />
                      <ListItemText primary={employee.full_name} />
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          )} */}
                </div>

                <div className="border-[1px] rounded-[10px] border-[#6E6E68] px-5 py-10">
                    {/* {user !== "Администратор" && (
            <>
              {managerSalary && (
                <Line
                  data={{
                    labels:
                      managerSalary?.result?.data?.chart_data?.map(
                        (data: any) =>
                          moment(data.label).format(
                            managerSalary?.result?.data.month
                              ? "DD/YYYY"
                              : "DD/MM/YYYY"
                          )
                      ) || [],
                    datasets: [
                      {
                        label: "Сумма зарплаты",
                        data:
                          managerSalary?.result?.data?.chart_data?.map(
                            (data: any) => data.data
                          ) || [],
                        fill: false,
                        borderColor: "#576ED0",
                        tension: 0.1,
                      },
                    ],
                  }}
                  options={{
                    scales: {
                      x: {
                        title: {
                          display: true,
                        },
                      },
                      y: {
                        title: {
                          display: true,
                        },
                      },
                    },
                  }}
                />
              )}
            </>
          )} */}
                    {user === 'Администратор' && (
                        <>
                            {analyticsManagerSalary && (
                                <Line
                                    data={{
                                        labels:
                                            analyticsManagerSalary?.result?.data?.manager_data?.[0]?.chart_data?.map(
                                                (data: any) =>
                                                    moment(data.label).format(
                                                        analyticsManagerSalary
                                                            ?.result?.data.month
                                                            ? 'DD/YYYY'
                                                            : 'DD/MM/YYYY',
                                                    ),
                                            ) || [],
                                        datasets:
                                            analyticsManagerSalary?.result?.data?.manager_data?.map(
                                                (item: any, index: number) => ({
                                                    label:
                                                        item.manager
                                                            ?.full_name || '',
                                                    data:
                                                        item.chart_data?.map(
                                                            (data: any) =>
                                                                data.data,
                                                        ) || [],
                                                    fill: false,
                                                    borderColor:
                                                        datasetColors[index],
                                                    backgroundColor:
                                                        datasetColors[index],
                                                }),
                                            ) || [],
                                    }}
                                    options={{
                                        scales: {
                                            x: {
                                                title: {
                                                    display: true,
                                                },
                                            },
                                            y: {
                                                title: {
                                                    display: true,
                                                },
                                            },
                                        },
                                        plugins: {
                                            legend: {
                                                display: true,
                                                labels: {
                                                    padding: 12,
                                                    boxWidth: 12,
                                                    boxHeight: 12,
                                                    font: {
                                                        size: 12,
                                                    },
                                                    color: '#000',
                                                },
                                            },
                                        },
                                    }}
                                />
                            )}
                        </>
                    )}
                </div>
            </div>

            <Modal
                open={modal.open}
                onClose={() => {
                    setModal(modalInitialValues)
                    setClient('')
                }}
            >
                <form
                    onSubmit={handleFormSubmit}
                    className="mainModal relative flex flex-col justify-start items-center"
                >
                    <IconButton
                        sx={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                        }}
                        onClick={() => {
                            setModal(modalInitialValues)
                            setClient('')
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <h2 className="text-[30px] font-[600] text-[#1E1C2A] mb-[60px]">
                        {modal.action === 'add' && 'Добавить зарплату'}
                        {modal.action === 'edit' && 'Редактировать зарплату'}
                        {modal.action === 'delete' && 'Удалить зарплату?'}
                    </h2>
                    {modal.action !== 'delete' && (
                        <div className="w-full grid grid-cols-2 gap-[30px] mb-[50px]">
                            <Autocomplete
                                freeSolo
                                isOptionEqualToValue={(option: any, value) =>
                                    option.full_name === value.full_name
                                }
                                getOptionLabel={(option: any) =>
                                    option.full_name
                                }
                                options={
                                    !managers.loading && !managers.error
                                        ? managers.result?.data
                                        : []
                                }
                                loading={managers.loading}
                                onChange={(event: any, newValue: any) => {
                                    setModal({
                                        ...modal,
                                        values: {
                                            ...modal.values,
                                            user: newValue.id,
                                        },
                                    })
                                }}
                                inputValue={client}
                                onInputChange={(event, newInputValue) => {
                                    setClient(newInputValue)
                                }}
                                sx={{ width: '100%' }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Менеджеры"
                                        required
                                        variant="standard"
                                    />
                                )}
                            />

                            <TextField
                                fullWidth
                                required={false}
                                label="Сумма бухгалтера"
                                variant="standard"
                                type={'number'}
                                value={modal.values.accountant_percentage}
                                error={
                                    modal.validation.error.accountant_percentage
                                }
                                helperText={
                                    modal.validation.message
                                        .accountant_percentage
                                }
                                onChange={(e) => {
                                    setModal({
                                        ...modal,
                                        values: {
                                            ...modal.values,
                                            accountant_percentage:
                                                e.target.value,
                                        },
                                    })
                                }}
                            />
                            <TextField
                                fullWidth
                                required
                                label="Оклад"
                                variant="standard"
                                type={'number'}
                                value={modal.values.salary}
                                error={modal.validation.error.salary}
                                helperText={modal.validation.message.salary}
                                onChange={(e) => {
                                    setModal({
                                        ...modal,
                                        values: {
                                            ...modal.values,
                                            salary: e.target.value,
                                        },
                                    })
                                }}
                            />

                            <TextField
                                fullWidth
                                required={false}
                                label="Сумма налога"
                                variant="standard"
                                type={'number'}
                                value={modal.values.tax}
                                error={modal.validation.error.tax}
                                helperText={modal.validation.message.tax}
                                onChange={(e) => {
                                    setModal({
                                        ...modal,
                                        values: {
                                            ...modal.values,
                                            tax: e.target.value,
                                        },
                                    })
                                }}
                            />

                            <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                adapterLocale={ru}
                            >
                                <DatePicker
                                    label="Дата"
                                    value={modal.values.created_at}
                                    onChange={(newValue: any) => {
                                        if (newValue) {
                                            const formattedDate =
                                                moment(newValue).format(
                                                    'YYYY-MM',
                                                )
                                            console.log(formattedDate)
                                            setModal({
                                                ...modal,
                                                values: {
                                                    ...modal.values,
                                                    created_at: formattedDate,
                                                },
                                            })
                                        }
                                    }}
                                    slotProps={{
                                        textField: {
                                            variant: 'standard',
                                            required: true,
                                            error: modal.validation.error
                                                .created_at,
                                            helperText:
                                                modal.validation.message
                                                    .created_at,
                                        },
                                    }}
                                    views={['year', 'month']}
                                    minDate={new Date('2000-01-01')}
                                    maxDate={new Date('2050-12-31')}
                                />
                            </LocalizationProvider>

                            {/* <DatePicker
                label="Начало даты"
                value={modal.values.start_date}
                onChange={(newValue) => {
                  setModal({
                    ...modal,
                    values: {
                      ...modal.values,
                      start_date: newValue,
                    },
                  });
                }}
                slotProps={{
                  textField: {
                    variant: "standard",
                    required: true,
                    error: modal.validation.error.start_date,
                    helperText: modal.validation.message.start_date,
                  },
                }}
              />
              <DatePicker
                label="Конец даты"
                value={modal.values.end_date}
                onChange={(newValue) => {
                  setModal({
                    ...modal,
                    values: {
                      ...modal.values,
                      end_date: newValue,
                    },
                  });
                }}
                slotProps={{
                  textField: {
                    variant: "standard",
                    required: true,
                    error: modal.validation.error.end_date,
                    helperText: modal.validation.message.end_date,
                  },
                }}
              /> */}

                            {/* <TextField
                fullWidth
                required
                label="Сумма от процента"
                variant="standard"
                type={"number"}
                value={modal.values.percentage_amount}
                error={modal.validation.error.percentage_amount}
                helperText={modal.validation.message.percentage_amount}
                onChange={(e) => {
                  setModal({
                    ...modal,
                    values: {
                      ...modal.values,
                      percentage_amount: e.target.value,
                    },
                  });
                }}
              /> */}
                        </div>
                    )}
                    <div className="w-full flex gap-[20px]">
                        <LoadingButton
                            style={{ borderRadius: '100px' }}
                            fullWidth
                            variant="outlined"
                            color="black"
                            onClick={() => {
                                setModal(modalInitialValues)
                                setClient('')
                            }}
                        >
                            Отменить
                        </LoadingButton>
                        <LoadingButton
                            style={{ borderRadius: '100px' }}
                            loading={modal.requested}
                            fullWidth
                            variant="contained"
                            color="black"
                            type="submit"
                        >
                            {modal.action === 'delete' && 'Удалить'}
                            {modal.action === 'edit' && 'Готово'}
                            {modal.action === 'add' && 'Добавить'}
                        </LoadingButton>
                    </div>
                </form>
            </Modal>
        </>
    )
}
